import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
let converter = require('json-2-csv');


function codeToJson (code){
  try{
    return converter.csv2json(code);
  }catch(exceptionVar){
    return {
      "error": "Failed to Parse CSV",
      "massage": exceptionVar
    }
  }

}

export default function CSVToJson() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}><JsonView src={codeToJson(code)} /></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "CSV to JSON",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/csvToJson",
    "description": "Effortlessly convert CSV data to JSON format with our intuitive CSV to JSON converter. Simplify your data processing tasks and streamline data transformation.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>CSV to JSON</title>
                <link rel="canonical" href="https://viewerforce.com/csvToJson" />
                <meta name="keywords" content="csv to json, csv viewer, csv formatter"></meta>
                <meta name="description" content="Effortlessly convert CSV data to JSON format with our intuitive CSV to JSON converter. Simplify your data processing tasks and streamline data transformation."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">CSV To JSON</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>CSV to JSON Converter</h2>
          <p>Welcome to our CSV to JSON Converter! This tool simplifies the process of converting CSV data to JSON format. Whether you're a developer, data analyst, or simply need to convert CSV files to JSON for further analysis, our converter provides a user-friendly interface for seamless conversion.</p>

  <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Effortless Conversion:</strong> Convert CSV data to JSON format quickly and effortlessly with our intuitive converter tool.</li>
        <li><strong>Flexible Input Options:</strong> Paste your CSV data directly into the provided textarea field, or upload CSV files for conversion.</li>
        <li><strong>Customizable Output:</strong> Customize the output JSON structure by specifying key-value pairs and other formatting options to meet your specific requirements.</li>
        <li><strong>Web-Based Convenience:</strong> Access our CSV to JSON converter directly from your web browser, eliminating the need for additional software installations.</li>
    </ul>
    </section>
   
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <dl>
        <dt>Q: Can I convert CSV files with complex structures?</dt>
        <dd>A: Our converter supports CSV files with various structures, including multi-line records and nested data, ensuring accurate conversion to JSON format.</dd>

        <dt>Q: Are there any limitations on the size of CSV files that can be converted?</dt>
        <dd>A: Our converter is designed to handle CSV files of various sizes, but extremely large files may require additional processing time.</dd>

        <dt>Q: Can I customize the formatting of the JSON output?</dt>
        <dd>A: Yes, our converter allows you to specify key-value pairs and other formatting options to customize the output JSON structure according to your preferences.</dd>

        <dt>Q: Is my data secure when using your CSV to JSON converter?</dt>
        <dd>A: We take user privacy and data security seriously. Our converter operates over secure connections (HTTPS) and does not store or transmit user data beyond what is necessary for its core functionality.</dd>
    </dl>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};