import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./HomePage.css"; // Assuming you have a separate CSS file for HomePage styling
import Footer from "./Footer";

export default function HomePage() {
  return (
    <div className="home-page-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Viewer Force - Home Page</title>
        <link rel="canonical" href="https://viewerforce.com" />
        <meta
          name="description"
          content="Welcome to Viewer Force - Empowering your data viewing experience. Explore powerful tools such as HTML Viewer, JSON Viewer, Base64 Decoder, and Base64 Encoder. Learn more about Viewer Force and contact our support team for assistance or feedback."
        />
      </Helmet>
      <header>
        <h1>Welcome to Viewer Force</h1>
        <p className="subtitle">Empowering your data viewing experience</p>
      </header>
      <main>
        <section className="tools">
        </section>
        <section className="about">
          <h2>About</h2>
          <p>
            Viewer Force is a versatile suite of tools built to facilitate
            seamless data exploration and manipulation. Whether you're a
            developer, data analyst, or simply curious about the inner workings
            of web content, Viewer Force empowers you to dissect, analyze, and
            interact with various data formats effortlessly.
          </p>
          <p>
            Our tools are meticulously crafted to offer a seamless user
            experience, with features such as syntax highlighting, code
            formatting, collapsible sections, and more, ensuring that you can
            focus on understanding your data without distractions.
          </p>
          <Link to="/about" className="learn-more">
            Learn More About Viewer Force
          </Link>
        </section>
        <section className="contact">
          <h2>Contact</h2>
          <p>
            We value your input and strive to continuously improve Viewer Force
            based on your feedback. Whether you have questions, suggestions for
            new features, or encounter any issues while using our tools,
            please don't hesitate to reach out to us.
          </p>
          <p>
            You can contact our support team via email at{" "}
            <a href="mailto:admin@viewerforce.com">support@viewerforce.com</a>{" "}
            or through our{" "}
            <Link to="/contact" className="contact-form">
              online contact form
            </Link>
            . We're here to assist you every step of the way!
          </p>
        </section>
      </main>
     
      <Footer/>
    </div>
  );
}
