import '../App.css';
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";


export default function Base64Encode() {

  const [code, setPostContent] = useState('');

  const viewSection =  <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}>{btoa(code)}</div>
</div>;

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = viewSection;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Base64 Encode",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/Base64Encode",
    "description": "Encode text to Base64 online with our free tool. Quickly encode plain text to Base64 format. No installation or registration required.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div className="app-container">
          <Helmet>
                <meta charSet="utf-8" />
                <title>Base64 Encode</title>
                <link rel="canonical" href="https://viewerforce.com/Base64Encode" />
                <meta name="keywords" content="Base64, Base64 Encode, String to Base64, text to Base64"></meta>
                <meta property="og:title" content="Base64 Encoder Tool"/>
                <meta property="og:description" content="Securely encode your data with our easy-to-use Base64 Encoder Tool."/>
                <meta property="og:url" content="https://viewerforce.com/Base64Encode"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="Base64 Encoder Tool"/>
                <meta name="twitter:description" content="Securely encode your data with our easy-to-use Base64 Encoder Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/Base64Encode"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="description" content="Encode text to Base64 online with our free tool. Quickly encode plain text to Base64 format. No installation or registration required."></meta>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">Base64 Encode</h1>
                  <div>
                    <div className="editor-container">
					 <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
              <h2>Welcome to Our Base64 Encoder Tool!</h2>
  <p>Easily encode and decode text or binary data using our Base64 encoder tool. Convert data to and from Base64 format effortlessly, enabling secure transmission and storage of information.</p>
  <p>Whether you're encoding sensitive data for transmission over the internet or decoding Base64-encoded files, our tool provides a simple and intuitive interface for all your Base64 encoding needs.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
            <li><strong>Encoding:</strong> Encode your data into Base64 format quickly and easily.</li>
            <li><strong>Decoding:</strong> Decode Base64-encoded data back into its original format.</li>
            <li><strong>File Support:</strong> Support for encoding and decoding text, files, and images.</li>
            <li><strong>Conversion Options:</strong> Customize encoding and decoding options to suit your needs.</li>
            <li><strong>User-Friendly Interface:</strong> Intuitive interface for hassle-free encoding and decoding.</li>
            <li><strong>Integration Options:</strong> Seamlessly integrate our Base64 encoder tool into your workflow.</li>
        </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose Our Base64 Encoder Tool:</h3>
        <ul>
            <li><strong>Fast and Efficient:</strong> Experience fast encoding and decoding performance with our optimized Base64 encoder tool.</li>
            <li><strong>User-Friendly Interface:</strong> Intuitive interface for users of all levels.</li>
            <li><strong>Secure and Reliable:</strong> Securely encode and decode your data with confidence.</li>
        </ul>
        <p>Unlock the full potential of Base64 encoding with our Base64 Encoder Tool Name. Try it now and simplify your data encoding process!</p>
    </section>
    <section>
  <h2>Frequently Asked Questions (FAQs) - Base64 Encoder</h2>
  <ul>
    <li>
      <strong>Q: What is Base64 encoding?</strong>
      <br />
      <strong>A:</strong> Base64 encoding is a method of converting binary data into ASCII text format. It is commonly used to encode binary data, such as images or files, into a text-based format that can be safely transmitted over text-based channels like email or HTTP.
    </li>
    <li>
      <strong>Q: How does the Base64 encoding process work?</strong>
      <br />
      <strong>A:</strong> In Base64 encoding, every three bytes of input data are converted into four ASCII characters. The resulting encoded data is padded with one or two equal signs (=) to ensure it is a multiple of four characters in length.
    </li>
    <li>
      <strong>Q: When should I use Base64 encoding?</strong>
      <br />
      <strong>A:</strong> Base64 encoding is commonly used in scenarios where binary data needs to be represented as text. This includes scenarios like embedding images or other binary files in HTML, sending binary data over email or HTTP, and storing binary data in text-based formats like XML or JSON.
    </li>
    <li>
      <strong>Q: Is Base64 encoding reversible?</strong>
      <br />
      <strong>A:</strong> Yes, Base64 encoding is reversible. The original binary data can be reconstructed by decoding the Base64-encoded text using a Base64 decoder.
    </li>
  </ul>
</section>
         </div>
         <Footer/>
      </div>
  );
}


const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};