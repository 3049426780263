import '../App.css';
import { md5 } from 'js-md5';
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";


function stringToMD5 (code){
  try{
    var hash = md5.create();
    hash.update(code);
    return hash.hex();     
  }catch(exceptionVar){
      return "Failed to Hash, "+ exceptionVar;
  }

}

export default function MD5() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}>{stringToMD5(code)}</div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "MD5 Hash Generator",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/md5",
    "description": "Easily convert JSON data to XML format with our intuitive JSON to XML converter.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>MD5 Hash Generator</title>
                <link rel="canonical" href="https://viewerforce.com/md5" />
                <meta name="keywords" content="md5 hash, hash online, string to hash"></meta>
                <meta name="description" content="Generate MD5 hashes quickly and securely with our MD5 hash generator tool. Protect your sensitive data and ensure data integrity with this cryptographic hash function."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
                 </Helmet>
                  <h1 align="center">MD5 Hash Generator</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>MD5 Hash Generator</h2>
          <p>Welcome to our MD5 Hash Generator tool! This tool allows you to generate MD5 hashes for any input data quickly and securely. Whether you're a developer, security professional, or simply need to hash sensitive data, our tool offers a reliable solution for cryptographic hashing.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Secure Hashing:</strong> Generate MD5 hashes using the widely-used cryptographic hash function for secure data protection.</li>
        <li><strong>Data Integrity:</strong> Use MD5 hashes to verify the integrity of your data and detect any unauthorized changes or tampering.</li>
        <li><strong>Simple Interface:</strong> Easily input your data and generate MD5 hashes with our intuitive and user-friendly interface.</li>
        <li><strong>Web-Based Convenience:</strong> Access our MD5 Hash Generator directly from your web browser, without the need for additional software installations.</li>
    </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose Our MD5 Hash Generator:</h3>
        <p>With numerous hash generator tools available, here's why our MD5 Hash Generator stands out:</p>
        <ul>
            <li><strong>Accuracy and Reliability:</strong> Our tool uses well-tested algorithms to ensure accurate and reliable MD5 hash generation for your data.</li>
            <li><strong>Speed and Efficiency:</strong> Generate MD5 hashes quickly and efficiently, even for large datasets, without compromising on performance.</li>
            <li><strong>Security and Privacy:</strong> We prioritize user privacy and data security, ensuring that your input data is not stored or transmitted beyond what is necessary for the hash generation process.</li>
            <li><strong>User-Friendly Interface:</strong> Our intuitive interface makes it easy for users of all levels to generate MD5 hashes with minimal effort and technical knowledge.</li>
        </ul>
    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <dl>
        <dt>Q: Is MD5 still secure?</dt>
        <dd>A: MD5 is considered weak for cryptographic purposes due to vulnerabilities such as collision attacks. It is not recommended for security-sensitive applications.</dd>

        <dt>Q: What are some alternatives to MD5?</dt>
        <dd>A: Secure hash functions such as SHA-256 and SHA-3 are recommended alternatives to MD5 for cryptographic hashing.</dd>

        <dt>Q: Can I use MD5 for non-security purposes?</dt>
        <dd>A: While MD5 is not recommended for security-sensitive applications, it can still be used for non-security purposes such as checksums or data fingerprinting.</dd>

        <dt>Q: Is my data secure when using your MD5 Hash Generator?</dt>
        <dd>A: We take user privacy and data security seriously. Our tool operates over secure connections (HTTPS) and does not store or transmit user data beyond what is necessary for the hash generation process.</dd>
    </dl>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};