import '../App.css';
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";

function parse64 (code){
  try{
    return atob(code)
  }catch(exceptionVar){
    return "Failed to parse base64: " + exceptionVar;
  }

}

export default function Base64Decode() {

  const [code, setPostContent] = useState('');

  const viewSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}>{parse64(code)}</div>
</div>;

  const devSection =<div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = viewSection;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Base64 Decode",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/Base64Decode",
    "description": "Decode text to Base64 online with our free tool. Quickly decode plain text to Base64 format. No installation or registration required.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Base64 Decode</title>
                <link rel="canonical" href="https://viewerforce.com/Base64Decode" />
                <meta name="keywords" content="Base64, Base64 Decode, Base64 to string, Base64 to text"></meta>
                <meta property="og:title" content="Base64 Decoder Tool"/>
                <meta property="og:description" content="Easily decode Base64 data back to its original format with our Base64 Decoder Tool."/>
                <meta property="og:url" content="https://viewerforce.com/Base64Decode/"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="Base64 Decoder Tool"/>
                <meta name="twitter:description" content="Easily decode Base64 data back to its original format with our Base64 Decoder Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/Base64Decode/"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="description" content="Decode text to Base64 online with our free tool. Quickly decode plain text to Base64 format. No installation or registration required."></meta>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">Base64 Decode</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
             <h2>Welcome to Our Base64 Decoder Tool!</h2>
  <p>Effortlessly decode Base64-encoded text or binary data using our Base64 decoder tool. Convert data from Base64 format back to its original form with ease, facilitating the extraction and interpretation of encoded information.</p>
  <p>Whether you're decoding messages, files, or data streams, our tool provides a simple and intuitive interface for all your Base64 decoding needs.</p>
  <section class="features">
        <h2>Key Features:</h2>
        <ul>
            <li><strong>Decoding:</strong> Decode Base64-encoded data back into its original format.</li>
            <li><strong>Encoding:</strong> Encode data into Base64 format quickly and easily.</li>
            <li><strong>File Support:</strong> Support for decoding text, files, and images encoded in Base64.</li>
            <li><strong>Conversion Options:</strong> Customize decoding options to suit your needs.</li>
            <li><strong>User-Friendly Interface:</strong> Intuitive interface for hassle-free decoding.</li>
            <li><strong>Integration Options:</strong> Seamlessly integrate our Base64 decoder tool into your workflow.</li>
        </ul>
    </section>
    <section class="why-choose">
        <h2>Why Choose Our Base64 Decoder Tool:</h2>
        <ul>
             <li><strong>Fast and Efficient:</strong> Experience fast decoding performance with our optimized Base64 decoder tool.</li>
            <li><strong>User-Friendly Interface:</strong> Intuitive interface for users of all levels.</li>
            <li><strong>Secure and Reliable:</strong> Decode your data securely and reliably.</li>
        </ul>
        <p>Unlock the full potential of Base64 decoding with our Base64 Decoder Tool Name. Try it now and simplify your data decoding process!</p>
    </section>
    <section>
  <h2>Frequently Asked Questions (FAQs) - Base64 Decoder</h2>
  <ul>
    <li>
      <strong>Q: What is Base64 decoding?</strong>
      <br />
      <strong>A:</strong> Base64 decoding is the process of converting Base64-encoded data back into its original binary format. It reverses the Base64 encoding process and restores the original binary data.
    </li>
    <li>
      <strong>Q: How does the Base64 decoding process work?</strong>
      <br />
      <strong>A:</strong> In Base64 decoding, each group of four Base64 characters is converted back into three bytes of binary data. The process involves reversing the mapping used in Base64 encoding to reconstruct the original binary data.
    </li>
    <li>
      <strong>Q: When should I use Base64 decoding?</strong>
      <br />
      <strong>A:</strong> Base64 decoding is useful in scenarios where you have Base64-encoded data and need to convert it back into its original binary form. This can include scenarios like decoding Base64-encoded email attachments, parsing Base64-encoded data in web applications, or extracting binary data from text-based formats like XML or JSON.
    </li>
    <li>
      <strong>Q: Is Base64 decoding reversible?</strong>
      <br />
      <strong>A:</strong> Yes, Base64 decoding is reversible. It accurately restores the original binary data from Base64-encoded text using a Base64 decoder.
    </li>
  </ul>
</section>
         </div>
         <Footer/>
      </div>
  );
}


const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};
