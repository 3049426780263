import React from "react";
import './App.css';


export default function Footer() {

  return (
    <div>

        
<section className="footerLinks" style={{ padding: '20px'}}>
    <h2 style={{ textAlign: 'center' }}>Our Tools:</h2>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: '20px' }}>
            <h3><a style={{ textDecoration: 'none' }} href='/JsonViewer'>Json Viewer</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/htmlViewer'>HTML Viewer</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/JsonToCsv'>Json To CSV</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/WhatIsMyip'>What Is My IP?</a></h3>
        </div>
        <div>
            <h3><a style={{ textDecoration: 'none' }} href='/Base64Encode'>Base64 Encoder</a></h3>
            <h3><a style={{ textDecoration: 'none' }} href='/Base64Decode'>Base64 Decoder</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/JsonToXml'>Json To XML</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/md5'>MD5 Generator</a></h3>
        </div>
        <div style={{ marginLeft: '20px' }}>
            <h3><a style={{ textDecoration: 'none' }} href='/XmlToJson'>Xml To Json</a></h3>
            <h3><a style={{ textDecoration: 'none' }} href='/YamlToJson'>Yaml To Json</a></h3>
            <h3><a style={{ textDecoration: 'none' }} href='/XmlToCsv'>Xml To CSV</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/sha1'>SHA1 Generator</a></h3>
        </div>
        <div style={{ marginLeft: '20px' }}>
            <h3><a style={{ textDecoration: 'none' }} href='/YamlValidator'>Yaml Validator</a></h3>
            <h3><a style={{ textDecoration: 'none' }} href='/CardValidetor'>Credit Card Validetor</a></h3>
            <h3><a style={{ textDecoration: 'none' }} href='/csvToJson'>Csv To Json</a></h3>
            <h3><a style={{  textDecoration: 'none' }} href='/sha3'>SHA3-512 Generator</a></h3>
        </div>
    </div>
</section>

    {/* More sections */}
    <footer className="footer">
        <nav>
        <ul>
            <li><a  className="footer-link" href="https://www.facebook.com/profile.php?id=61558688707142">Follow us on Facebook</a></li>
            <li><a  className="footer-link" href="https://twitter.com/viewerforce">Follow us on X</a></li>
            <li><a href="/about" className="footer-link">About</a></li>
            <li><a href="/contact" className="footer-link">Contact Us</a></li>
            <li><a href="/privacyPolicy" className="footer-link">Privacy Policy</a></li>
        </ul>
        </nav>
        <p>&copy; 2024 Viewer Force. All rights reserved.</p>
    </footer>
  </div>
  );

}
