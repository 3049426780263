import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer";

export default function PrivacyPolicy() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  };

  const headerStyle = {
  //  backgroundColor: "#f0f0f0",
    padding: "10px",
  };

  const navStyle = {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  };

  const navItemStyle = {
    display: "inline",
    marginRight: "10px",
  };

  const sectionStyle = {
    marginTop: "20px",
  };

  const sectionTitleStyle = {
    fontSize: "24px",
    color: "#333",
  };

  const sectionContentStyle = {
    lineHeight: "1.6",
  };

  const footerStyle = {
    marginTop: "20px",
    backgroundColor: "#f0f0f0",
    padding: "10px",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Viewer Force - Privacy Policy</title>
        <link rel="canonical" href="https://viewerforce.com" />
        <meta name="description" content="Read our Privacy Policy to understand how we collect, use, and protect your personal information on our website. Your privacy is important to us, and we are committed to safeguarding your data." />
      </Helmet>

      <header style={headerStyle}>
        <h1>Privacy Policy</h1>
        <nav>
          <ul style={navStyle}>
            <li style={navItemStyle}><a href="/">Home</a></li>
            <li style={navItemStyle}><a href="/about">About</a></li>
            <li style={navItemStyle}><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <section style={sectionStyle}>
        <h2 style={sectionTitleStyle}>Introduction</h2>
        <p style={sectionContentStyle}>
          This Privacy Policy explains how Viewer Force ("we", "us", or "our") collects, uses, shares, and protects your personal information when you use our website.
          Please read this Privacy Policy carefully to understand our practices regarding your personal data and how we will treat it. By accessing or using our website, you agree to the terms of this Privacy Policy.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={sectionTitleStyle}>Information We Collect</h2>
        <p style={sectionContentStyle}>
          We collect information that you provide to us directly when you use our website, such as when you fill out forms or contact us.
          This may include your name, email address, and any other information you choose to provide.
        </p>
        {/* Add more information about data collection */}
      </section>

      {/* Add more sections for data use, sharing, security, and other relevant information */}

      <Footer/>
    </div>
  );
}
