import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";


function codeToJson (code){
  try{
    return JSON.parse(code)
  }catch(exceptionVar){
    return {
      "error": "Failed to Parse Json",
      "massage": exceptionVar
    }
  }

}

export default function JsonViewer() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}><JsonView src={codeToJson(code)} /></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }

  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Json Viewer",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/JsonViewer",
    "description": "A versatile JSON viewer tool crafted to visualize JSON data structures effectively, offering features for syntax highlighting, collapsible/expandable nodes, and interactive exploration of JSON objects and arrays.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Json Viewer</title>
                <link rel="canonical" href="https://viewerforce.com/JsonViewer" />
                <meta name="keywords" content="json, json viewer, json formatter"></meta>
                <meta property="og:title" content="JSON Viewer Tool"/>
                <meta property="og:description" content="Navigate and visualize JSON effortlessly with our powerful and intuitive JSON Viewer Tool."/>
                <meta property="og:url" content="https://viewerforce.com/JsonViewer/"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="JSON Viewer Tool"/>
                <meta name="twitter:description" content="Navigate and visualize JSON effortlessly with our powerful and intuitive JSON Viewer Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/JsonViewer/"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="description" content="A versatile JSON viewer tool crafted to visualize JSON data structures effectively, offering features for syntax highlighting, collapsible/expandable nodes, and interactive exploration of JSON objects and arrays."></meta>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">Json Viewer</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>Welcome to Our JSON Viewer Tool!</h2>
  <p>Explore and visualize JSON data effortlessly with our JSON viewer tool. Easily navigate through JSON objects, arrays, and properties with a user-friendly interface.</p>
  <p>Whether you're analyzing API responses, debugging code, or simply examining JSON files, our tool provides a convenient way to interpret and interact with JSON data.</p>

  <section class="features">
        <h3>Key Features:</h3>
        <ul>
            <li><strong>Syntax Highlighting:</strong> Our JSON viewer tool highlights syntax elements, making it easy to identify keys, values, and objects.</li>
            <li><strong>Formatting:</strong> Messy JSON data becomes organized and easy to read with our formatting feature.</li>
            <li><strong>Collapsible Elements:</strong> Collapse and expand JSON objects and arrays for better navigation and readability.</li>
            <li><strong>Error Detection:</strong> Identify and fix errors efficiently with our error detection feature.</li>
            <li><strong>Search Functionality:</strong> Quickly find specific keys or values within large JSON data sets with our search functionality.</li>
            <li><strong>Integration Options:</strong> Seamlessly integrate our JSON viewer tool into your development workflow.</li>
        </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose our JSON Viewer Tool:</h3>
        <ul>
            <li><strong>Fast and Efficient:</strong> Experience lightning-fast performance and efficiency with our optimized JSON viewer tool.</li>
            <li><strong>User-Friendly Interface:</strong> Our intuitive interface caters to users of all skill levels.</li>
            <li><strong>Secure and Reliable:</strong> Rest assured knowing that your JSON data is safe and secure with our robust encryption and data protection measures.</li>
        </ul>
        <p>Unlock the full potential of your JSON data analysis with our JSON Viewer Tool. Try it now and revolutionize the way you work with JSON data!</p>
    </section>
    <section>
  <h2>Frequently Asked Questions (FAQs) - JSON Viewer</h2>
  <ul>
    <li>
      <strong>Q: What is a JSON viewer?</strong>
      <br />
      <strong>A:</strong> A JSON viewer is a tool used to visualize and navigate JSON (JavaScript Object Notation) data in a structured and user-friendly format. It helps users to inspect and understand the structure of JSON data, making it easier to work with complex JSON objects.
    </li>
    <li>
      <strong>Q: How does a JSON viewer work?</strong>
      <br />
      <strong>A:</strong> A JSON viewer parses JSON data and displays it in a hierarchical format, typically using collapsible tree structures. Users can expand and collapse nodes to explore nested objects and arrays within the JSON data. Some JSON viewers also offer features like syntax highlighting, search functionality, and formatting options.
    </li>
    <li>
      <strong>Q: What are the benefits of using a JSON viewer?</strong>
      <br />
      <strong>A:</strong> Using a JSON viewer provides several benefits, including:
      <ul>
        <li>Improved readability: JSON viewers present JSON data in a visually appealing and structured manner, making it easier to read and understand.</li>
        <li>Easy navigation: Users can navigate through complex JSON structures effortlessly using expandable/collapsible nodes.</li>
        <li>Debugging and troubleshooting: JSON viewers help developers debug and troubleshoot JSON data by identifying errors, inconsistencies, or missing fields.</li>
        <li>Validation: Some JSON viewers offer validation features to ensure that JSON data conforms to the JSON schema and syntax rules.</li>
      </ul>
    </li>
    <li>
      <strong>Q: Can I edit JSON data using a JSON viewer?</strong>
      <br />
      <strong>A:</strong> Some advanced JSON viewers allow users to edit JSON data directly within the viewer interface. However, it's essential to exercise caution when editing JSON data, especially in production environments, to avoid unintentional changes or errors.
    </li>
  </ul>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};