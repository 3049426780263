import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
var valid = require("card-validator");

function validateCreditCard (code){
  try{
    const cardObject =  valid.number (code);
    if (!cardObject.isPotentiallyValid) {
      return {
        "massage": "Card is invalid!"
      }
    }
    return cardObject;
    
  }catch(exceptionVar){
   
  }

}

export default function CreditCardValidetor() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}><JsonView src={validateCreditCard(code)} /></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Credit Card Validetor",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/CardValidetor",
    "description": "Validate credit card numbers instantly with our free Credit Card Validator tool. Check the validity of Visa, Mastercard, American Express, and Discover cards. No registration required.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Credit Card Validetor</title>
                <link rel="canonical" href="https://viewerforce.com/CardValidetor" />
                <meta name="keywords" content="Credit card validator"></meta>
                <meta name="description" content="Validate credit card numbers instantly with our free Credit Card Validator tool. Check the validity of Visa, Mastercard, American Express, and Discover cards. No registration required."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">Credit Card Validetor</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
      <h1>Credit Card Validator</h1>
    <p>Validate credit card numbers instantly with our free Credit Card Validator tool. Simply enter the credit card number in the input field below and click the "Validate" button to check its validity.</p>
    <p>Our validator supports all major credit card brands, including Visa, Mastercard, American Express, and Discover. Whether you're verifying card details for online transactions or ensuring data accuracy in your applications, our tool makes the process quick and hassle-free.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Efficient Validation:</strong> Instantly verify the validity of credit card numbers.</li>
        <li><strong>Supports Major Brands:</strong> Validate Visa, Mastercard, American Express, and Discover cards.</li>
        <li><strong>User-Friendly Interface:</strong> Easy-to-use tool for quick validation without any technical knowledge required.</li>
        <li><strong>Secure and Confidential:</strong> Your credit card data is processed securely, and we do not store or retain any information after validation.</li>
    </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose our credic card validator Tool:</h3>
        <p>When it comes to validating credit card numbers, our tool offers several advantages:</p>
    <ul>
        <li><strong>Reliability:</strong> Built on robust technology, our validator ensures accurate verification of credit card numbers.</li>
        <li><strong>User-Friendly Interface:</strong> Designed for simplicity, our tool makes it easy to validate credit card numbers without any hassle.</li>
        <li><strong>Immediate Feedback:</strong> Receive instant feedback on the validity of credit card numbers, allowing for quick resolution.</li>
    </ul>
    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <ul>
        <li>
            <strong>Is this validator free to use?</strong>
            <p>Yes, our Credit Card Validator is completely free to use. There are no hidden charges or subscription fees.</p>
        </li>
        <li>
            <strong>Does your validator support all credit card brands?</strong>
            <p>Our validator supports all major credit card brands, including Visa, Mastercard, American Express, and Discover.</p>
        </li>
        <li>
            <strong>Is my credit card data secure during the validation process?</strong>
            <p>Yes, we prioritize the security and confidentiality of your data. Your credit card numbers are processed securely, and we do not store or retain any information after validation.</p>
        </li>
    </ul>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};