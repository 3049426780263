import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
var convert = require('xml-js');


function codeToJson (code){
  try{
    return JSON.parse(convert.xml2json(code, {compact: false, spaces: 4}));
  }catch(exceptionVar){
    return {
      "error": "Failed to Parse XML",
      "massage": exceptionVar
    }
  }

}

export default function XmlToJson() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}><JsonView src={codeToJson(code)} /></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "XML to Json",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/XmlToJson",
    "description": "Explore and analyze XML files easily with our XML viewer. View, search, and navigate through XML documents effortlessly.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>XML to Json</title>
                <link rel="canonical" href="https://viewerforce.com/XmlToJson" />
                <meta name="keywords" content="xml to json, xml viewer, xml formatter, convert xml to json"></meta>
                <meta name="description" content="Explore and analyze XML files easily with our XML viewer. View, search, and navigate through XML documents effortlessly."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">XML To Json</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>XML to JSON Converter</h2>
          <p>Converting XML data to JSON format has never been easier. Simply paste your XML code into the input area below and click the "Convert" button to get JSON output.</p>
    <p>Our converter supports a wide range of XML structures, including nested elements, attributes, and text nodes. Whether you're dealing with XML data from APIs, databases, or other sources, our tool ensures seamless conversion to JSON.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Efficient Conversion:</strong> Instantly convert XML to JSON with just a click of a button.</li>
        <li><strong>Preserve Data Integrity:</strong> Our converter maintains the structure and content of your XML document during the conversion process.</li>
        <li><strong>Customizable Output:</strong> Customize the formatting and indentation of the JSON output to suit your preferences.</li>
        <li><strong>Cross-Platform Compatibility:</strong> Access our converter from any device or platform, including desktops, laptops, tablets, and smartphones.</li>
        <li><strong>Secure and Confidential:</strong> We prioritize the security and confidentiality of your data, ensuring that your XML documents are safe throughout the conversion process.</li>
    </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose our JSON Viewer Tool:</h3>
        <p>When it comes to converting XML to JSON, there are many options available. Here's why our converter stands out:</p>
    <ul>
        <li><strong>Reliability:</strong> Our converter is built on robust technology, guaranteeing reliable performance and accurate results every time.</li>
        <li><strong>User-Friendly Interface:</strong> Designed with simplicity in mind, our tool caters to users of all skill levels, from novices to seasoned developers.</li>
        <li><strong>Secure and Confidential:</strong> We prioritize the security and confidentiality of your data. Rest assured that your XML documents are safe and protected throughout the conversion process.</li>
    </ul>
    <p>Experience the convenience of converting XML to JSON seamlessly with our web tool. Try it now and unlock a world of possibilities for your data interchange needs!</p>
    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <ul>
        <li>
            <strong>Is this converter free to use?</strong>
            <p>Yes, our XML to JSON converter is completely free to use. There are no hidden charges or subscription fees.</p>
        </li>
        <li>
            <strong>Does your converter support large XML files?</strong>
            <p>While our converter is designed to handle a wide range of XML sizes, extremely large files may affect performance. For optimal results, we recommend processing XML files of moderate size.</p>
        </li>
        <li>
            <strong>Can I convert JSON back to XML?</strong>
            <p>Currently, our tool only supports conversion from XML to JSON. However, there are other tools available online that offer the reverse conversion.</p>
        </li>
        <li>
            <strong>Is my data secure during the conversion process?</strong>
            <p>Yes, we prioritize the security and confidentiality of your data. Your XML documents are processed securely, and we do not store or retain any data after the conversion is complete.</p>
        </li>
    </ul>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};