import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
var convert = require('xml-js');
let converter = require('json-2-csv');


function codeToCsv (code){
  try{
    return converter.json2csv(JSON.parse(convert.xml2json(code, {compact: false, spaces: 4})));
  }catch(exceptionVar){
    return exceptionVar;
  }

}

export default function XmlToCsv() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}>{codeToCsv(code)}</div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }

  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "XML to CSV",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/XmlToCsv",
    "description": "Effortlessly convert XML data to CSV format with our intuitive XML to CSV converter. Preserve data integrity and streamline your data processing tasks.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>XML to CSV</title>
                <link rel="canonical" href="https://viewerforce.com/XmlToCsv" />
                <meta name="keywords" content="convert xml to csv, xml to csv"></meta>
                <meta name="description" content="Effortlessly convert XML data to CSV format with our intuitive XML to CSV converter. Preserve data integrity and streamline your data processing tasks."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">XML To CSV</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>XML to CSV Converter</h2>
          <p>Welcome to our XML to CSV Converter! This tool simplifies the process of converting XML data to CSV format. Whether you're a developer, data analyst, or simply need to convert XML files to CSV for further analysis, our converter provides a user-friendly interface for seamless conversion.</p>

          <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Effortless Conversion:</strong> Convert XML data to CSV format quickly and effortlessly with our intuitive converter tool.</li>
        <li><strong>Preserve Data Integrity:</strong> Ensure the integrity of your data during conversion, with support for various XML structures and attributes.</li>
        <li><strong>Flexible Output Options:</strong> Customize the output CSV file by specifying delimiter, quote characters, and other formatting options to meet your specific requirements.</li>
        <li><strong>Web-Based Convenience:</strong> Access our XML to CSV converter directly from your web browser, eliminating the need for additional software installations.</li>
    </ul>
    </section>

    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <dl>
        <dt>Q: Can I convert XML files with complex structures?</dt>
        <dd>A: Yes, our converter supports XML files with various structures and attributes, ensuring accurate conversion to CSV format.</dd>

        <dt>Q: Are there any limitations on the size of XML files that can be converted?</dt>
        <dd>A: Our converter is designed to handle XML files of various sizes, but extremely large files may require additional processing time.</dd>

        <dt>Q: Can I customize the formatting of the CSV output?</dt>
        <dd>A: Yes, our converter allows you to specify delimiter, quote characters, and other formatting options to customize the output CSV file according to your preferences.</dd>

        <dt>Q: Is my data secure when using your XML to CSV converter?</dt>
        <dd>A: We take user privacy and data security seriously. Our converter operates over secure connections (HTTPS) and does not store or transmit user data beyond what is necessary for its core functionality.</dd>
    </dl>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};