import '../App.css';
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";


function convertObjectToXML(obj, indent = 0) {
    var xml = '';

    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            xml += addIndent(indent);
            xml += "<" + key + ">";

            if (typeof obj[key] === "object") {
                xml += "\n" + convertObjectToXML(obj[key], indent + 1);
                xml += addIndent(indent);
            } else {
                xml += obj[key];
            }

            xml += "</" + key + ">\n";
        }
    }

    return xml;
}

function addIndent(indent) {
    var spaces = '';
    for (var i = 0; i < indent; i++) {
        spaces += '    '; // 4 spaces for each level of indentation
    }
    return spaces;
}

function convertToXml (code){
  try{
    return convertObjectToXML(JSON.parse(code));
  }catch(exceptionVar){
    return "Failed to Parse Json, " + exceptionVar;
  }

}

export default function JsonToXML() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}>{convertToXml(code)}</div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Json To XML",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/JsonToXml",
    "description": "Easily convert JSON data to XML format with our intuitive JSON to XML converter.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Json To XML</title>
                <link rel="canonical" href="https://viewerforce.com/JsonToXml" />
                <meta property="og:title" content="JSON to XML Tool"/>
                <meta property="og:description" content="Convert JSON data to XML format effortlessly with our JSON to XML Tool."/>
                <meta property="og:url" content="https://viewerforce.com/JsonToXml"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="JSON to XML Tool"/>
                <meta name="twitter:description" content="Convert JSON data to XML format effortlessly with our JSON to XML Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/JsonToXml"/>
                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta name="keywords" content="json to xml, json to xml converter"></meta>
                <meta name="description" content="Easily convert JSON data to XML format with our intuitive JSON to XML converter."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">Json To XML</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>JSON to XML Converter</h2>
          <p>Welcome to our JSON to XML Converter! This tool simplifies the process of converting JSON data to XML format. Whether you're a developer, data analyst, or simply need to convert JSON files to XML for further processing, our converter provides a user-friendly interface for seamless conversion.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Easy Conversion:</strong> Convert JSON data to XML format quickly and effortlessly with our intuitive converter tool.</li>
        <li><strong>Hierarchical Representation:</strong> Maintain the hierarchical structure of your JSON data in the resulting XML, preserving the relationships between objects and arrays.</li>
        <li><strong>Customizable Output:</strong> Customize the output XML by specifying indentation levels and formatting options to suit your preferences.</li>
        <li><strong>Web-Based Convenience:</strong> Access our JSON to XML converter directly from your web browser, eliminating the need for additional software installations.</li>
    </ul>
    </section>
    <section class="why-choose">
    <h2>How to Use:</h2>
    <ol>
        <li><strong>Input JSON Data:</strong> Enter your JSON data in the provided textarea field. You can input JSON objects, arrays, or nested structures.</li>
        <li><strong>Click Convert:</strong> Click the "Convert" button to initiate the conversion process. Our tool will parse the JSON data and generate the corresponding XML output.</li>
        <li><strong>View XML Output:</strong> Once the conversion is complete, you'll see the resulting XML formatted with proper indentation, making it easy to read and understand.</li>
    </ol>

    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <dl>
        <dt>Q: Can I convert complex JSON structures with nested objects and arrays?</dt>
        <dd>A: Yes, our converter supports complex JSON structures, including nested objects and arrays, ensuring accurate conversion to XML format.</dd>

        <dt>Q: Are there any limitations on the size of JSON data that can be converted?</dt>
        <dd>A: Our converter is designed to handle JSON data of various sizes, but extremely large files may require additional processing time.</dd>

        <dt>Q: Can I customize the formatting of the XML output?</dt>
        <dd>A: Yes, our converter allows you to specify indentation levels and other formatting options to customize the output XML according to your preferences.</dd>

        <dt>Q: Is my data secure when using your JSON to XML converter?</dt>
        <dd>A: We take user privacy and data security seriously. Our converter operates over secure connections (HTTPS) and does not store or transmit user data beyond what is necessary for its core functionality.</dd>
    </dl>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};