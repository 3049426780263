import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
const yaml = require('js-yaml');


function validateYaml (code){
  try{
     yaml.load (code);
     return {
      "success": "Yaml is Valid!!",
    }
  }catch(exceptionVar){
    return {
      "error": "Failed to Parse Yaml",
      "massage": exceptionVar
    }
  }

}

export default function YamlValidator() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}><JsonView src={validateYaml(code)} /></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Yaml Validator",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/YamlValidator",
    "description": "Validate YAML syntax with ease using our free YAML Validator tool. Quickly identify and correct syntax errors in your YAML documents. No installation required.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Yaml Validator</title>
                <link rel="canonical" href="https://viewerforce.com/YamlValidator" />
                <meta name="keywords" content="Yaml to json, yaml validator, yaml viewer, yaml visualizer"></meta>
                <meta name="description" content="Validate YAML syntax with ease using our free YAML Validator tool. Quickly identify and correct syntax errors in your YAML documents. No installation required."/>
                <meta property="og:title" content="YAML Validator Tool"/>
                <meta property="og:description" content="Ensure your YAML files are error-free with our powerful YAML Validator Tool."/>
                <meta property="og:url" content="https://viewerforce.com/YamlValidator"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="YAML Validator Tool"/>
                <meta name="twitter:description" content="Ensure your YAML files are error-free with our powerful YAML Validator Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/YamlValidator"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>

            </Helmet>
                  <h1 align="center">Yaml Validator</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
      <h1>YAML Validator</h1>
        <p>Ensure the validity of your YAML syntax with our validator tool. Paste your YAML code into the input area below and click the "Validate" button to check for any errors or syntax issues.</p>
        <p>Our validator analyzes your YAML document and provides feedback on any detected errors, allowing you to correct them quickly and efficiently.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
            <li><strong>Efficient Validation:</strong> Quickly validate YAML syntax with just a click of a button.</li>
            <li><strong>Accurate Detection:</strong> Our validator accurately identifies syntax errors and provides detailed feedback for resolution.</li>
            <li><strong>User-Friendly Interface:</strong> Designed for simplicity, our tool makes it easy to identify and correct YAML syntax issues.</li>
            <li><strong>Immediate Feedback:</strong> Receive instant feedback on any errors detected in your YAML document, allowing for quick resolution.</li>
            <li><strong>Cross-Platform Compatibility:</strong> Access our validator from any device or platform, including desktops, laptops, tablets, and smartphones.</li>
            <li><strong>Secure and Confidential:</strong> We prioritize the security and confidentiality of your data, ensuring that your YAML documents are processed securely.</li>
        </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose our Yaml validator Tool:</h3>
        <p>When it comes to validating YAML syntax, our tool offers several advantages:</p>
        <ul>
            <li><strong>Reliability:</strong> Built on robust technology, our validator ensures accurate detection of syntax errors.</li>
            <li><strong>User-Friendly Interface:</strong> Designed for simplicity, our tool makes it easy to identify and correct YAML syntax issues.</li>
            <li><strong>Immediate Feedback:</strong> Receive instant feedback on any errors detected in your YAML document, allowing for quick resolution.</li>
        </ul>
    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <ul>
            <li>
                <strong>Is this validator free to use?</strong>
                <p>Yes, our YAML validator is completely free to use. There are no hidden charges or subscription fees.</p>
            </li>
            <li>
                <strong>Does your validator support large YAML files?</strong>
                <p>While our validator is designed to handle YAML files of various sizes, extremely large files may affect performance. For optimal results, we recommend validating YAML files of moderate size.</p>
            </li>
            <li>
                <strong>Can your validator fix YAML syntax errors automatically?</strong>
                <p>No, our validator does not automatically fix YAML syntax errors. However, it provides feedback on detected errors, allowing you to manually correct them.</p>
            </li>
            <li>
                <strong>Is my data secure during the validation process?</strong>
                <p>Yes, we prioritize the security and confidentiality of your data. Your YAML documents are processed securely, and we do not store or retain any data after the validation is complete.</p>
            </li>
        </ul>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};