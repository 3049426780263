import React, { useState } from "react";
import '../App.css';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Footer from "../Footer";
import {Helmet} from "react-helmet";

export default function HtmlViewer() {
  const [code, setCode] = useState('');


  const iframeSection =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> <iframe
     style={{width: '100%', height: '100%' }}
      sandbox
      srcDoc={code}
    ></iframe></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  const resultSection = code ? iframeSection : devSection;
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "Html Viewer",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/htmlViewer",
    "description": "A powerful HTML viewer tool designed to render HTML documents with ease, providing features for syntax highlighting, code formatting, and interactive browsing of web content.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
    <div className="app-container">
         <Helmet>
                <meta charSet="utf-8" />
                <title>Html Viewer</title>
                <link rel="canonical" href="https://viewerforce.com/htmlViewer" />
                <meta name="keywords" content="html viewer, html editor, html visualizer"></meta>
                <meta property="og:title" content="HTML Viewer Tool"/>
                <meta property="og:description" content="Beautify and organize your HTML code with our user-friendly HTML Viewer Tool."/>
                <meta property="og:url" content="https://viewerforce.com/htmlViewer"/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:title" content="HTML Viewer Tool"/>
                <meta name="twitter:description" content="Beautify and organize your HTML code with our user-friendly HTML Viewer Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/htmlViewer"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="description" content="A powerful HTML viewer tool designed to render HTML documents with ease, providing features for syntax highlighting, code formatting, and interactive browsing of web content."></meta>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
      <h1 align="center">HTML Viewer</h1>
      <div className="editor-container">
      <div>Enter text here:</div>
      <textarea
                      name="text"
                      value={code}
                      onChange={e => setCode(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
      </div>
      {resultSection}
      <div className="info-section">
      <h2>Welcome to Our HTML Viewer Tool!</h2>
  <p>Effortlessly view and interact with HTML content in a user-friendly interface. Explore web pages, documents, and presentations with ease using our intuitive HTML viewer. Access, analyze, and enjoy HTML files seamlessly.</p>
  <p>Whether you're a developer, designer, student, or professional, our HTML viewer empowers you to engage with HTML files in a user-friendly and efficient manner.</p>

  <section >
        <h3>Key Features:</h3>
        <ul>
            <li><strong>Syntax Highlighting:</strong> Our HTML viewer tool highlights syntax elements, making it easy to identify tags, attributes, and values.</li>
            <li><strong>Code Beautification:</strong> Messy HTML code becomes clean and organized with our code beautification feature.</li>
            <li><strong>Real-Time Preview:</strong> Visualize changes instantly with our real-time preview feature.</li>
            <li><strong>Responsive Design Testing:</strong> Test your HTML code's responsiveness with our built-in tools.</li>
            <li><strong>Error Detection:</strong> Identify and fix errors efficiently with our error deatection feature.</li>
            <li><strong>Integration Options:</strong> Seamlessly integrate our HTML viewer tool into your development workflow.</li>
        </ul>
    </section>
    <section >
        <h3>Why Choose our HTML Viewer:</h3>
        <ul>
            <li><strong>Fast and Efficient:</strong> Experience lightning-fast performance and efficiency with our optimized HTML viewer tool.</li>
            <li><strong>User-Friendly Interface:</strong> Our intuitive interface caters to developers of all skill levels.</li>
            <li><strong>Secure and Reliable:</strong> Rest assured knowing that your HTML code is safe and secure with our robust encryption and data protection measures.</li>
        </ul>
        <p>Unlock the full potential of your HTML development journey with our HTML Viewer. Try it now and revolutionize the way you work with HTML code!</p>
    </section>
    <section >
  <h2>FAQs</h2>
  <p>Find answers to frequently asked questions:</p>
  <ul>
    <li>
      <strong>Q:</strong> How do I create links in HTML?
      <br />
      <strong>A:</strong> Use the <code>&lt;a&gt;</code> tag with the <code>href</code> attribute to create links.
    </li>
    <li>
      <strong>Q:</strong> What is the difference between <code>&lt;div&gt;</code> and <code>&lt;span&gt;</code> in HTML?
      <br />
      <strong>A:</strong> <code>&lt;div&gt;</code> is a block-level element used for grouping content, while <code>&lt;span&gt;</code> is an inline element used for styling portions of text.
    </li>
    <li>
      <strong>Q:</strong> How do I add comments in HTML?
      <br />
      <strong>A:</strong> Use the <code>&lt;!-- --&gt;</code> syntax to add comments in HTML.
    </li>
    <li>
      <strong>Q:</strong> Can I use JavaScript with HTML?
      <br />
      <strong>A:</strong> Yes, you can use JavaScript within HTML to add interactivity and dynamic behavior to your web pages.
    </li>
  </ul>
</section>
</div>
  <Footer/>
    </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '100px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
    overflow: 'auto'
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    height: '50vh',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    height: '80%',
    width: '100%'
  },
};