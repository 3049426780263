import React from "react";
import { Helmet } from "react-helmet";

export default function Contact() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  };

  const headerStyle = {
    padding: "10px",
  };

  const navStyle = {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  };

  const navItemStyle = {
    display: "inline",
    marginRight: "10px",
  };

  const contactSectionStyle = {
    marginTop: "20px",
  };

  const sectionTitleStyle = {
    fontSize: "24px",
    color: "#333",
  };

  const sectionContentStyle = {
    lineHeight: "1.6",
  };

  const footerStyle = {
    marginTop: "20px",
    padding: "10px",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Viewer Force - Contact Us</title>
        <link rel="canonical" href="https://viewerforce.com" />
        <meta name="description" content="Get in touch with us through our contact page to share feedback, ask questions, or inquire about partnerships. We're here to assist you and welcome your suggestions for improving our online tools. Find our contact details and a convenient form for reaching out to our team." />
      </Helmet>

      <header style={headerStyle}>
        <h1>Contact Us</h1>
        <nav>
          <ul style={navStyle}>
            <li style={navItemStyle}><a href="/">Home</a></li>
            <li style={navItemStyle}><a href="/about">About</a></li>
            <li style={navItemStyle}><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <section style={contactSectionStyle} className="contact">
        <h2 style={sectionTitleStyle}>Get in Touch</h2>
        <p style={sectionContentStyle}>Have a question, feedback, or need support? We're here to help! Feel free to reach out to us via email, and we'll get back to you as soon as possible.</p>
        <h3 style={sectionTitleStyle}>Email:</h3>
        <p style={sectionContentStyle}><a href="mailto:admin@viewerforce.com">admin@viewerforce.com</a></p>
      </section>

      <footer style={footerStyle} className="footer-contact">
        <p>&copy; 2024 Viewer Force. All rights reserved.</p>
      </footer>
    </div>
  );
}
