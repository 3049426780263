import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HtmlViewer from "./htmlViewer/HtmlViewer";
import Base64Decode from "./base64Decode/Base64Decode";
import Base64Encode from "./base64Encode/Base64Encode";
import JsonViewer from "./jsonViewer/JsonViewer";
import About from "./About";
import Contact from "./Contact";
import HomePage from "./HomePage";
import XmlToJson from "./XmlToJson/XmlToJson";
import YamlToJson from "./YamlToJson/YamlToJson";
import YamlValidator from "./YamlValidator/YamlValidator";
import CreditCardValidetor from "./credicCardValidator/CreditCardValidetor";
import JSONToCSV from "./jsonToCSV/JsonToCSV";
import JsonToXML from "./JsonToXML/JsonToXML";
import XmlToCsv from "./xmlToCsv/xmlToCsv";
import CSVToJson from "./csvToJson/csvToJson";
import WhatIsMyIP from "./whatIsMyIp/WhatIsMyIp";
import MD5 from "./md5/md5";
import SHA1 from "./sha1/sha1";
import SHA3 from "./sha3/sha3";
import PrivacyPolicy from "./privacyPolicy/privacyPolicy";
export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route  index  element={<HomePage />}/>
        <Route  path="/htmlViewer"  element={<HtmlViewer />}/>
        <Route  path="/JsonViewer"  element={<JsonViewer />}/>
        <Route  path="/Base64Encode"  element={<Base64Encode />}/>
        <Route  path="/Base64Decode"  element={<Base64Decode />}/>
        <Route  path="/XmlToJson"  element={<XmlToJson />}/>
        <Route  path="/YamlToJson"  element={<YamlToJson />}/>
        <Route  path="/YamlValidator"  element={<YamlValidator />}/>
        <Route  path="/CardValidetor"  element={<CreditCardValidetor/>}/>
        <Route  path="/JsonToCsv"  element={<JSONToCSV/>}/>
        <Route  path="/JsonToXml"  element={<JsonToXML/>}/>
        <Route  path="/XmlToCsv"  element={<XmlToCsv/>}/>
        <Route  path="/csvToJson"  element={<CSVToJson/>}/>
        <Route  path="/WhatIsMyip"  element={<WhatIsMyIP/>}/>
        <Route  path="/md5"  element={<MD5/>}/>
        <Route  path="/sha1"  element={<SHA1/>}/>
        <Route  path="/sha3"  element={<SHA3/>}/>
        <Route  path="/about"  element={<About />}/>
        <Route  path="/contact"  element={<Contact />}/>
        <Route  path="/privacyPolicy"  element={<PrivacyPolicy />}/>
      </Routes>
    </BrowserRouter>
  );

}
