import '../App.css';
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";




export default function WhatIsMyIP() {

    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    const ip = req.getResponseHeader("ip");
    const country = req.getResponseHeader("country");
    let countrySection = '';
    if(country!= ''){
      countrySection= <div> <br/>
      <div style={styles.resultTitle}>Country of origin:</div>
      <div style={styles.resultText}>{country}</div></div>
    }

  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Your IP is:</div>
  <div style={styles.resultText}>{ip}</div>
  {countrySection}
</div>;
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "What Is My IP",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/WhatIsMyip",
    "description": "Quickly find your public IP address with our 'What is My IP' tool. Get instant access to your IP address for networking, troubleshooting, and privacy protection.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>What Is My IP</title>
                <link rel="canonical" href="https://viewerforce.com/WhatIsMyip" />
                <meta name="keywords" content="find my ip, what is my ip, my ip location"></meta>
                <meta name="description" content="Quickly find your public IP address with our 'What is My IP' tool. Get instant access to your IP address for networking, troubleshooting, and privacy protection."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">What Is My IP?</h1>
                  <div>
                    
                 {section}
                 
      </div>
      <div className="info-section">     
          <h2>What is My IP</h2>
          <p>Welcome to our "What is My IP" tool! This tool provides a quick and easy way to find your public IP address. Whether you're a developer, network administrator, or simply curious about your current IP address, our tool offers instant access to this information.</p>

          <section class="features">
          <h3>Key Features:</h3>
    <ul>
        <li><strong>Instant IP Lookup:</strong> Get your public IP address instantly without any additional setup or configuration.</li>
        <li><strong>Networking and Troubleshooting:</strong> Use your IP address for networking tasks, troubleshooting connectivity issues, and configuring network devices.</li>
        <li><strong>Privacy Protection:</strong> Understand your public IP address to protect your privacy online and ensure secure browsing.</li>
        <li><strong>Web-Based Convenience:</strong> Access our "What is My IP" tool directly from your web browser, anytime and anywhere you need to check your IP address.</li>
    </ul>
    </section>
    <section class="why-choose">
    <h3>Why Choose Our Tool:</h3>
    <p>With numerous tools available for finding your public IP address, here's why our "What is My IP" tool stands out:</p>
    <ul>
        <li><strong>Speed and Simplicity:</strong> Our tool provides an instant IP lookup experience without any unnecessary steps or distractions.</li>
        <li><strong>Reliability:</strong> We use reputable sources to ensure accurate and up-to-date information about your IP address.</li>
        <li><strong>Privacy Focus:</strong> We prioritize user privacy and security, ensuring that your IP address is not stored or transmitted beyond what is necessary for the lookup process.</li>
        <li><strong>Accessibility:</strong> Our tool is accessible from any device with an internet connection, making it convenient to check your IP address whenever you need to.</li>
    </ul>
    </section>
    <section>
    <h3>Frequently Asked Questions (FAQ)</h3>
    <dl>
        <dt>Q: Is my public IP address unique?</dt>
        <dd>A: Yes, your public IP address is unique and serves as the identifier for your device on the internet.</dd>

        <dt>Q: Can I hide or change my public IP address?</dt>
        <dd>A: Yes, you can use VPNs (Virtual Private Networks) or proxy servers to hide or change your public IP address for privacy or security reasons.</dd>

        <dt>Q: Why do I need to know my IP address?</dt>
        <dd>A: Knowing your IP address is important for various networking tasks, troubleshooting internet connectivity issues, configuring network devices, and ensuring online privacy.</dd>

        <dt>Q: Is my IP address secure when using your tool?</dt>
        <dd>A: Yes, we take user privacy and data security seriously. Our tool operates over secure connections (HTTPS) and does not store or transmit user IP addresses beyond what is necessary for the IP lookup process.</dd>
    </dl>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};