import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
const yaml = require('js-yaml');


function codeToJson (code){
  try{
    return yaml.load (code);
  }catch(exceptionVar){
    return {
      "error": "Failed to Parse Yaml",
      "massage": exceptionVar
    }
  }

}

export default function YamlToJson() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}><JsonView src={codeToJson(code)} /></div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }

  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "YAML to JSON Tool",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/YamlToJson",
    "description": "Convert YAML to JSON online with this free web tool. No installation required.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}

  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Yaml to Json</title>
                <link rel="canonical" href="https://viewerforce.com/YamlToJson" />
                <meta name="keywords" content="Yaml to json, yaml validator, yaml viewer, yaml visualizer"></meta>
                <meta property="og:title" content="YAML to JSON Tool"/>
                <meta property="og:description" content="Effortlessly convert YAML files to JSON with our user-friendly YAML to JSON Tool."/>
                <meta property="og:url" content="https://viewerforce.com/YamlToJson"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="YAML to JSON Tool"/>
                <meta name="twitter:description" content="Effortlessly convert YAML files to JSON with our user-friendly YAML to JSON Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/YamlToJson"/>
                <meta name="twitter:card" content="summary_large_image"/>

                <meta name="description" content="Convert YAML to JSON online with this free web tool. No installation required."/>
                <meta name="keywords" content="YAML, JSON, converter, tool, online, free"/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">YAML To JSON</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
      <h1>YAML to JSON Converter</h1>
        <p>Convert YAML data to JSON format effortlessly. Paste your YAML code into the input area below and click the "Convert" button to generate JSON output.</p>
        <p>Our converter supports YAML documents with various structures, including nested objects, arrays, and key-value pairs. Whether you're working with configuration files, data serialization, or API responses, our tool simplifies the conversion process.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
            <li><strong>Efficient Conversion:</strong> Instantly convert YAML to JSON with just a click of a button.</li>
            <li><strong>Preserve Data Integrity:</strong> Our converter maintains the structure and content of your YAML document during the conversion process.</li>
            <li><strong>Customizable Output:</strong> Customize the formatting and indentation of the JSON output to suit your preferences.</li>
            <li><strong>Cross-Platform Compatibility:</strong> Access our converter from any device or platform, including desktops, laptops, tablets, and smartphones.</li>
            <li><strong>Secure and Confidential:</strong> We prioritize the security and confidentiality of your data, ensuring that your YAML documents are safe throughout the conversion process.</li>
        </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose our Yaml to Json Tool:</h3>
        <p>When it comes to converting YAML to JSON, our converter offers several advantages:</p>
        <ul>
            <li><strong>Reliability:</strong> Built on robust technology, our converter ensures reliable performance and accurate results.</li>
            <li><strong>User-Friendly Interface:</strong> Designed for simplicity, our tool caters to users of all skill levels, making the conversion process effortless.</li>
            <li><strong>Secure and Confidential:</strong> We prioritize data security, ensuring that your YAML documents are processed securely and your information remains confidential.</li>
        </ul>
    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <ul>
            <li>
                <strong>Is this converter free to use?</strong>
                <p>Yes, our YAML to JSON converter is completely free to use. There are no hidden charges or subscription fees.</p>
            </li>
            <li>
                <strong>Does your converter support large YAML files?</strong>
                <p>While our converter is designed to handle a wide range of YAML file sizes, extremely large files may affect performance. For optimal results, we recommend processing YAML files of moderate size.</p>
            </li>
            <li>
                <strong>Can I convert JSON back to YAML?</strong>
                <p>Currently, our tool only supports conversion from YAML to JSON. However, there are other tools available online that offer the reverse conversion.</p>
            </li>
            <li>
                <strong>Is my data secure during the conversion process?</strong>
                <p>Yes, we prioritize the security and confidentiality of your data. Your YAML documents are processed securely, and we do not store or retain any data after the conversion is complete.</p>
            </li>
        </ul>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};