import React from "react";
import { Helmet } from "react-helmet";

export default function About() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  };

  const headerStyle = {
    padding: "10px",
  };

  const navStyle = {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  };

  const navItemStyle = {
    display: "inline",
    marginRight: "10px",
  };

  const aboutSectionStyle = {
    marginTop: "20px",
  };

  const sectionTitleStyle = {
    fontSize: "24px",
    color: "#333",
  };

  const sectionContentStyle = {
    lineHeight: "1.6",
  };

  const subsectionTitleStyle = {
    fontSize: "20px",
    color: "#555",
  };

  const footerStyle = {
    marginTop: "20px",
    padding: "10px",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Viewer Force - About</title>
        <link rel="canonical" href="https://viewerforce.com" />
        <meta name="description" content="Learn about our mission and vision behind our online tools website, designed to simplify tasks, streamline processes, and enhance productivity. Discover the team behind the scenes, our values, and commitment to providing high-quality tools for our users." />
      </Helmet>

      <header style={headerStyle}>
        <h1>About Us</h1>
        <nav>
          <ul style={navStyle}>
            <li style={navItemStyle}><a href="/">Home</a></li>
            <li style={navItemStyle}><a href="/about">About</a></li>
            <li style={navItemStyle}><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <section style={aboutSectionStyle}>
        <h2 style={sectionTitleStyle}>Welcome to Viewer Force</h2>
        <p style={sectionContentStyle}>Welcome to our platform! We are a dynamic team of seasoned developers driven by our shared passion for simplifying coding tasks. Our primary objective is to furnish you with top-notch tools tailored to streamline your coding endeavors. These tools are meticulously crafted to be not only user-friendly but also highly efficient and reliable, ensuring a seamless coding experience for you.</p>

        <h3 style={subsectionTitleStyle}>Our Tools:</h3>
        <ul>
        <li><h4><a href="/htmlViewer">HTML Viewer Tool</a></h4></li>
        <li><h4><a href="/JsonViewer">JSON Viewer Tool</a></h4></li>
        <li><h4><a href="/Base64Encode">Base64 Encoder Tool</a></h4></li>
        <li><h4><a href="/Base64Decode">Base64 Decoder Tool</a></h4></li>
        <li><h4><a href="/JsonToCsv">Json To CSV</a></h4></li>
        <li><h4><a href="/WhatIsMyip">What Is My IP?</a></h4></li>
        <li><h4><a href="/JsonToXml">Json To XML</a></h4></li>
        <li><h4><a href="/md5">MD5 Generator</a></h4></li>
        <li><h4><a href="/XmlToJson">Xml To Json</a></h4></li>
        <li><h4><a href="/YamlToJson">Yaml To Json</a></h4></li>
        <li><h4><a href="/XmlToCsv">Xml To CSV</a></h4></li>
        <li><h4><a href="/sha1">SHA1 Generator</a></h4></li>
        <li><h4><a href="/YamlValidator">Yaml Validator</a></h4></li>
        <li><h4><a href="/CardValidetor">Credit Card Validetor</a></h4></li>
        <li><h4><a href="/csvToJson">Csv To Json</a></h4></li>
        <li><h4><a href="/sha3">SHA3-512 Generator</a></h4></li>
      </ul>

        <h3 style={subsectionTitleStyle}>Our Mission</h3>
        <p>At our core, we are on a mission to revolutionize the development landscape by creating tools that serve as catalysts for productivity. We understand the time and effort investment that goes into coding, which is why our mission is centered around developing solutions that significantly reduce the complexities involved. Whether it's encoding data, decoding JSON, or parsing HTML, our innovative tools are designed to empower you, enabling you to focus more on your coding creativity and less on tedious tasks.</p>
    
        <h3 style={subsectionTitleStyle}>Why Choose Us?</h3>
        <p>Choosing us means choosing excellence, performance, and security. Our unwavering dedication to enhancing user experience is reflected in the design and functionality of our tools. We go the extra mile to ensure that our products not only meet but exceed your expectations. By embracing open-source technologies, we foster a culture of transparency and collaboration, inviting developers like you to contribute and shape the future of our tools. When you choose us, you're not just choosing a vendor; you're choosing a trusted partner committed to your success in every coding project you undertake.</p>
      <p>Thank you for choosing us as your trusted partner in coding. We look forward to serving you and helping you succeed in your projects.</p>


        <p>Thank you for choosing us as your trusted partner in coding. We look forward to serving you and helping you succeed in your projects.</p>
      </section>

      <section>
        <h3 style={subsectionTitleStyle}>FAQs</h3>
        <p>Welcome to our FAQ section, where we address some of the common queries you may have about our tools and services. Here's a comprehensive overview:</p>
        <ul>
    <li>
      <strong>Q:</strong> What are these tools for?
      <br />
      <strong>A:</strong> Our tools serve as invaluable aids for developers tackling a variety of coding tasks. From viewing and formatting JSON data to analyzing HTML code and encoding/decoding data in Base64 format, our tools are designed to streamline your workflow and enhance productivity.
    </li>
    <li>
      <strong>Q:</strong> Are these tools free to use?
      <br />
      <strong>A:</strong> Yes, indeed! All of our tools are completely free to use, and there are no hidden fees or subscriptions required. Enjoy unrestricted access to our suite of tools for both personal and commercial projects.
    </li>
    <li>
      <strong>Q:</strong> Can I use these tools for commercial projects?
      <br />
      <strong>A:</strong> Absolutely! Whether you're working on a personal project or a commercial venture, our tools are at your disposal. Feel free to incorporate them into your commercial projects without any restrictions.
    </li>
    <li>
      <strong>Q:</strong> How often are the tools updated?
      <br />
      <strong>A:</strong> We're committed to delivering the best possible experience to our users. As such, we continually strive to improve and update our tools based on user feedback and emerging industry standards. Be sure to check our website regularly for the latest updates and exciting new features.
    </li>
    <li>
      <strong>Q:</strong> How do I report a bug or suggest a new feature?
      <br />
      <strong>A:</strong> Your feedback is invaluable to us! If you encounter any bugs or have ideas for new features, please don't hesitate to reach out to us. You can report bugs or suggest new features by creating an issue or contacting us through the support channels listed in our Contact Us section.
    </li>
    <li>
      <strong>Q:</strong> Can I integrate these tools into my development workflow?
      <br />
      <strong>A:</strong> While we do not offer direct integration options, you're more than welcome to share our tools with your colleagues and integrate them into your development workflow as needed.
    </li>
    <li>
      <strong>Q:</strong> Are these tools available offline?
      <br />
      <strong>A:</strong> Our tools are web-based and require an internet connection to access. Unfortunately, they are not available offline at this time.
    </li>
    <li>
      <strong>Q:</strong> Can I trust the security and privacy of these tools?
      <br />
      <strong>A:</strong> Absolutely. We take the security and privacy of our users seriously. Our tools are designed with industry-standard security practices, and we do not store or collect any of your data. All processing is conducted locally within your browser or on your machine, ensuring the utmost privacy and security for your data.
    </li>
        </ul>
        <p>We hope this FAQ section has addressed any questions or concerns you may have had. If you have any further inquiries, please don't hesitate to reach out to us. We're here to help!</p>
      </section>

      <footer style={footerStyle}>
        <p>&copy; 2024 Viewer Force. All rights reserved.</p>
      </footer>
    </div>
  );
}