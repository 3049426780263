import '../App.css';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import React, { useState } from "react";
import Footer from '../Footer';
import {Helmet} from "react-helmet";
let converter = require('json-2-csv');


function codeToCSV (code){
  try{
    return converter.json2csv(JSON.parse(code));
  }catch(exceptionVar){
      return "Failed to convert Json 2 CSV, "+ exceptionVar;
  }

}

export default function JSONToCSV() {

  const [code, setPostContent] = useState();
  

  
  const section =   <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}>{codeToCSV(code)}</div>
</div>;
  

  const devSection = <div style={styles.resultContainer}>
  <div style={styles.resultTitle}>Result:</div>
  <div style={styles.resultText}> </div>
</div>

  let resultSection = devSection;
  if(code != '' && code != undefined){
    resultSection = section;
  }
  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "WebSite",
    "name": "JSON to CSV",
    "image": "https://viewerforce.com/favicon.jpg",
    "url": "https://viewerforce.com/JsonToCsv",
    "description": "Convert JSON data to CSV format effortlessly with our intuitive online tool. Simply upload your JSON file and download the corresponding CSV file in seconds.",
    "isPartOf": {
        "@type": "Website",
        "name": "Viewer Force",
        "url": "https://viewerforce.com"
    },
    "sameAs" : [
        "https://www.facebook.com/profile.php?id=61558688707142",
        "https://twitter.com/viewerforce"
    ]
}
  return (
      <div style={{ paddingTop: '5vh'}}>
         <Helmet>
                <meta charSet="utf-8" />
                <title>JSON to CSV</title>
                <link rel="canonical" href="https://viewerforce.com/JsonToCsv" />
                <meta name="keywords" content="json to csv, json csv converter"></meta>
                <meta property="og:title" content="JSON to CSV Tool"/>
                <meta property="og:description" content="Easily convert JSON data to CSV format with our efficient JSON to CSV Tool."/>
                <meta property="og:url" content="https://viewerforce.com/JsonToCsv"/>
                <meta property="og:type" content="website"/>
                <meta name="twitter:title" content="JSON to CSV Tool"/>
                <meta name="twitter:description" content="Easily convert JSON data to CSV format with our efficient JSON to CSV Tool."/>
                <meta name="twitter:url" content="https://viewerforce.com/JsonToCsv"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="description" content="Convert JSON data to CSV format effortlessly with our intuitive online tool. Simply upload your JSON file and download the corresponding CSV file in seconds."/>
                <script type="application/ld+json">
        {JSON.stringify(ldJson)}
        </script>
            </Helmet>
                  <h1 align="center">JSON to CSV</h1>
                  <div>
                    <div className="editor-container">
                    <div>Enter text here:</div>
                    <textarea
                      name="text"
                      value={code}
                      onChange={e => setPostContent(e.target.value)}
                      rows={4}
                      cols={40}
                      style={styles.textArea}
                    />
                 
                      
                    </div>
                    
                 {resultSection}
                 
      </div>
      <div className="info-section">     
          <h2>Json to CSV Converter</h2>
          <p>Our JSON to CSV converter is a free online tool designed to simplify the process of converting JSON data to CSV format. Whether you're a developer, data analyst, or simply need to convert JSON files to CSV for further analysis, our tool provides the functionality you need with the convenience of web-based access.</p>
  <section class="features">
        <h3>Key Features:</h3>
        <ul>
        <li><strong>Simple Conversion:</strong> Convert JSON data to CSV format quickly and easily with our intuitive converter tool.</li>
        <li><strong>Supports Complex Structures:</strong> Our tool handles complex JSON structures, including nested objects and arrays, ensuring accurate conversion to CSV files.</li>
        <li><strong>Customizable Output:</strong> Customize the output CSV file by specifying delimiter, quote characters, and other formatting options to meet your specific requirements.</li>
        <li><strong>Web-Based Convenience:</strong> Access our JSON to CSV converter directly from your web browser, eliminating the need for additional software installations.</li>
        <li><strong>Fast and Efficient:</strong> Enjoy fast and efficient conversion of JSON data to CSV format, saving you time and streamlining your data processing tasks.</li>
    </ul>
    </section>
    <section class="why-choose">
        <h3>Why Choose our JSON Viewer Tool:</h3>
        <ul>
        <li><strong>Accuracy:</strong> Our converter ensures accurate conversion of JSON data to CSV format, preserving the integrity of your data throughout the process.</li>
        <li><strong>User-Friendly Interface:</strong> With a simple and intuitive interface, our tool makes it easy for users of all skill levels to convert JSON to CSV with minimal effort.</li>
        <li><strong>Flexibility:</strong> Whether you're dealing with small JSON datasets or large, complex structures, our converter offers the flexibility you need to handle various data processing tasks effectively.</li>
    </ul>    </section>
    <section>
    <h2>Frequently Asked Questions (FAQ)</h2>
    <dl>
        <dt>Q: Can your JSON to CSV converter handle nested JSON structures?</dt>
        <dd>A: Yes, our converter supports nested JSON structures, including nested objects and arrays, ensuring accurate conversion to CSV format.</dd>

        <dt>Q: Are there any limitations on the size of JSON files that can be converted?</dt>
        <dd>A: Our converter is designed to handle JSON files of various sizes, but extremely large files may require additional processing time.</dd>

        <dt>Q: Can I customize the delimiter and other formatting options for the CSV output?</dt>
        <dd>A: Yes, our converter allows you to specify delimiter, quote characters, and other formatting options to customize the output CSV file according to your preferences.</dd>

        <dt>Q: Is your JSON to CSV converter available for offline use?</dt>
        <dd>A: Currently, our converter is web-based and requires an internet connection. However, we may explore offline options in the future.</dd>

        <dt>Q: How secure is my data when using your JSON to CSV converter?</dt>
        <dd>A: We take user privacy and data security seriously. Our converter operates over secure connections (HTTPS) and does not store or transmit user data beyond what is necessary for its core functionality.</dd>
    </dl>
</section>
  </div>
  <Footer/>
      </div>
  );
}



const styles = {
  textArea: {
    backgroundColor: "#f5f5f5",
    color: '#333',
    fontSize: '18px',
    width: '100%',
    height: '50px',
    padding: '18px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    resize: 'none',
  },
  resultContainer: {
    width: '60vw',
    marginLeft: '20vw',
    color: '#333',
    marginTop: '20px',
    backgroundColor: "#f5f5f5",
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  resultTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#333',
  },
  resultText: {
    fontSize: '16px',
    color: '#333',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};